.Feedback .titile {
  display: flex;
  justify-content: space-between;
}
.Feedback .titile button {
  border: none;
  padding-left: 46px;
  padding-right: 40px;
  /* display: flex; */
  border: 1px solid #ced4da;
  border-radius: 3px;
}
.Feedback .titile input {
  border: 1px solid #ced4da;
  border-radius: 3px;
}/*# sourceMappingURL=feedback.css.map */