.user .user-images {
  display: flex;
  justify-content: center;
}
.user .css-h4y409-MuiList-root, .user .css-1p823my-MuiListItem-root {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}
.user .bodrer ul {
  border: 1px solid #ced4da;
  border-radius: 3px;
}/*# sourceMappingURL=User.css.map */