.Addvechile .nav-pills .nav-link.active, .Addvechile .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #8197ce;
}
.Addvechile .nav-link:focus, .Addvechile .nav-link {
  color: #131414;
}
.Addvechile .nav-link:focus, .Addvechile .nav-link:hover {
  color: #131414;
}
.Addvechile .admin-view {
  display: flex;
}
.Addvechile ul#pills-tab {
  display: flex;
  gap: 9px;
}
.Addvechile .admin-view button {
  width: 214px;
  font-size: 20px;
  padding: 5px;
}
.Addvechile .admin-view .butt-view {
  text-align: center;
}/*# sourceMappingURL=AddVechile.css.map */