.card-body p{
    color: red;
}
.login .card-body{
    padding: 80px!important;
}
.register .card-body{
    padding: 60px!important;
}
.card {
    border-radius: 0px!important;
}
.leftside-logo{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 25px;
    background-color: rgb(85, 92, 92);
}
.login button, .register button{
    width: 150px;
    padding: 10px;
    border-radius: 50px;
}

.butt{
    padding-top: 40px;
}
.login .form-control:focus, .dataTable-input:focus{
    box-shadow: none;
    border-color:#9d8686;
    font-family: 'Poppins', sans-serif;
}
.register .form-control:focus, .dataTable-input:focus{
    box-shadow: none;
    border-color:#9d8686;
}

.login input.form-control, .register input.form-control {
    border: none;
    border-bottom: 1px solid #9d8686;
    border-radius: 0px;
 
}
