.masterlayout{
    #layoutSidenav_content{
        background-color: #e5e5e5;
    }
    .btn-link {
        font-weight: 400;
        color: #d5dae0;
        text-decoration: underline;
    }
    .btn-link:hover {
        color: #d5dae0;
    }
    .nav-bgco{
        background-color: #28396D;
    }
    .nav-bgcor{
        background-image: linear-gradient(to left, rgba(10, 52, 110, 0.4) , #28396D
        );
    }
    nav.sb-topnav.navbar.navbar-expand.navbar-dark.nav-bgcor {
        border-bottom: 1px solid rgb(232, 237, 238);
    }
    .sb-sidenav a.nav-link{
        color: #fff;
    }
    .logo-font {
        color: #fff;
        font-weight: 300;
        font-size: 28px;
    }

    .heading h5{
        color: #fff;
        padding-left: 50px;
        font-size: 25px;
        margin: 0px;
        font-weight: 600;
        font-family: 'Poppins', sans-serif;
    
    }
    .ico{
        color: #fff!important;
    }
  
    
    
}