.Feedback{
.titile{
    display: flex;
    justify-content: space-between;  
    button {
        border: none;
        padding-left: 46px;
        padding-right: 40px;
        /* display: flex; */
        border: 1px solid #ced4da;
            border-radius: 3px;
      }
      input{
        border: 1px solid #ced4da;
        border-radius: 3px;
      
      }
}

}