.vendormasterlayout{
    .nav-bgco{
        background-color: #b3446c;
    }
    .logo-font {
        color: #fff;
        font-weight: 300;
        font-size: 28px;
    }
    .navi-bg svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root{
        color: #fff;
    }
    .btn-link {
        font-weight: 400;
        color: #d5dae0;
        text-decoration: underline;
    }
    .btn-link:hover {
        color: #d5dae0;
    }
    .nav-bgco{
        background-color: #b3446c;
    }
    .sb-sidenav a.nav-link{
        color: #fff;
    }
    .nav-bgco svg.svg-inline--fa.fa-user.fa-fw {
        color: #fff;
    }

}