@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200;1,300&display=swap');

.admin-dash {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    width: 100%;
}

.col-md-4 .card {
    padding: 20px;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    color: #000000;
    border-radius: 5px !important;
    font-family: 'Poppins', sans-serif;
    position: relative;
}

.col-md-4 .card::after {
    content: "";
    position: absolute;
    background-image: url('../../assets/admin/assets//img/design.png');
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;

}

.col-md-4 .card:hover {
    transition: 0.5s;
    transform: scale(1.05)
}

.title h5{
    font-size: 16px;
}

.nav-item button {
    font-size: 16px;
    line-height: 28px;
    color: #fff;
}

.Addvechile li.nav-item {
    border: 1px solid #dfdede;
    color: #fff;
    border-radius: 5px;
}

.card-body p {
    color: red;
    margin: 0px;
    
}
.Reports p{
    color: red;
    margin: 0px;
    position: absolute;
    
}

.cards-design .title {
    display: flex;

}

.value {
    text-align: left;
    font-size: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 600;
}

.admin-dash .card {
    border-radius: 10px;
}

.col-md-4:nth-child(2) .card {
    background-color: #FFA896;

}

.col-md-4:nth-child(3) .card {
    background-color: #6AB6F2;

}

.col-md-4:nth-child(4) .card {
    background-color: #52D5C4;

}

.col-md-4:nth-child(5) .card {
    background-color:
        #FE7D8E;

}

.col-md-4:nth-child(6) .card {
    background-color:
        #07D892;

}

.col-md-4:nth-child(7) .card {
    background-color: #FF976E;

}

.col-md-4:nth-child(8) .card {
    background-color: #02BCBD;

}

.col-md-4:nth-child(9) .card {
    background-color: #FFB73A;

}

.col-md-4:nth-child(10) .card {
    background-color: #FF6767;

}

.bar {
    display: flex;
}

select {
    background-image: url('/src/assets/admin/assets/img/downarrow.png');
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: right 30px bottom 10px;
    background-position-y: center;
}

.callwithstatus {
    padding: 5px;
    border-radius: 5px;

}

.callwithstatus a.pending {
    background: #29abe2;
    color: #fff;
    border-radius: 5px;
}

table th {
    text-align: start;
}

tbody small {
    text-align: center;
}

.heads h6 {
    color:
        #28396D;
    font-size: 22px;
}

.cards-design .card {
    position: relative;
}

.icons {
    position: absolute;
    content: "";
    width: 80px;
    height: 80px;
    background-color: white;
    border-radius: 50px;
    right: 30px;
    display: flex;
    justify-content: center;
}

.nav-link p {
    font-size: 16px;
    margin: 0px;
    line-height: 28px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.55);
    font-family: 'Poppins', sans-serif;
}

a.nav-link:hover {
    border-radius: 5px;
    background: linear-gradient(180deg, #4B6CCE 0%, #7B93DA 100%);
    box-shadow: 0px 4px 24px 1px rgba(35, 16, 94, 0.25);
    backdrop-filter: blur(45px);
    border-radius: 5px;
    margin-left: 10px;
    margin-right: 10px;
}

.sb-sidenav .sb-sidenav-menu .nav .nav-link {
    padding-left: 30px !important;
}

.approval .title h4 {
    color: #28396D;
    font-size: 22px !important;
    line-height: 38px !important;
}

ul.navbar-nav.ms-auto.ms-md-0.me-3.me-lg-4 {
    display: flex;
    align-items: center;
    color: #fff !important;
}

.nav-icons svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
    color: #fff !important;
    margin: 0px 10px !important;
}

.search-box input {
    width: 397px;
    padding: 10px 15px;
    display: flex;
    justify-content: end;
}

.search-box input::placeholder {
    font-size: 18px;
    padding-left: 20px;
}

.heading {
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;
}

.heading h4 {
    font-size: 18px;
    margin: 0px;
    padding-left: 25px;
    color: #fff;
    text-transform: capitalize;
}

.container-fluid.approval.Export {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px;
    margin-bottom: 15px;
}
.container-fluid.Export {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px;
    margin-bottom: 15px;
}

tr td {
    padding: 16px!important;
    color: #656565!important;
    font-size: 18px;
    align-items: center;
    font-family: 'Poppins', sans-serif;
}
tr th{
    padding: 21px!important;
    font-size: 20px!important;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
}
.actio{
    text-align: center;
}

th {
    font-weight: 400;
    font-size: 17px;
}

.emails {
    text-align: left;
}

.last-row {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: baseline;
}

.pro-name {
    display: flex;
    justify-content: start;
    padding-left: 10px;
}

.pagination {
    padding-right: 40px;
}

.actions {
    display: flex;
    justify-content: space-evenly;
}

.cust li {
    list-style-type: disclosure-closed;
}

.title h4 {
    display: flex;
    justify-content: space-between;
}

.sb-nav-link-icon svg {
    color: #fff;
}

.cust-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cards-design a {
    text-decoration: none;
}

.row.filter.mt-3.py-3 {
    display: flex;
    align-items: end;
}

.maps Map {
    height: 300px !important;
    width: 100%;
}

img.profile-admin {
    width: 100%;
    height: 100%;
}

iframe {
    display: none;
}

/* small.bell-icon {
    background-color: rgb(235, 14, 14)
}

li .bell-icon {
    color: rgb(24, 73, 129);
    font-size: 18px;
    font-weight: 700;
    border-radius: 100px;
    text-decoration: none;
    position: relative;
    left: -11px;
    top: -11px;
    padding: 3px;
    color: #ffff;
} */

/* .bell-icon {
    display: none;
} */
.first-bell{
    position: relative;
}
.bell-icon{
    position: absolute;
    content: "";
    background-color: red;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    top: -8px;
    right: 0px;
    display: flex;
    justify-content:center;
    align-items: center;
    border-radius: 100%;
    width: 19px;
    height: 19px;
}
.masterlayout li a {
    text-decoration: none;
}

.first-bell {
    position: relative;
}

.first-bell ul li {
    list-style-type: none;
}

.second-bellnull {
    position: absolute;
    top: 62px;
    left: -15px;
    color: #000;
}

.childeren-nidify ul {
    padding: 0px;
}

/* .second-bellnull li {
    height: auto;
    width: 100%;
    border-radius: 28px;
    margin-left: auto;
    margin-right: auto;
    padding: 11px;
    background-color: #7fffd447;
} */


.second-bellbell {
    display: none;
}

.cards {
    top: -23px;
    position: absolute;
    left: -248px;
    color: #fff;
    width: 330px;
    background-color: #6181a5;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    transition: 0.5s;
}

.cards::after {
    content: " ";
    position: absolute;
    right: 30px;
    top: -15px;
    border-top: none;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 15px solid #6181a5;
    ;
}

.head {
    color: rgb(243, 247, 246);
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.head h6{
    color: #fff;

}

.childeren-nidify {
    padding: 10px;

}

.n_old {
    height: auto;
    width: 100%;
    border-radius: 28px;
    margin-left: auto;
    margin-right: auto;
    padding: 11px;
    border: 1px solid #ffff;
    margin-bottom: 10px;

   
}

.n_new {
    height: auto;
    width: 100%;
    border-radius: 28px;
    margin-left: auto;
    margin-right: auto;
    padding: 11px;
    margin-bottom: 5px;
    background-color: #7fffd447;
}
.order-view{
    display: flex;
    justify-content: space-between;
}
.form-control {
    width: 100%!important;
    font-size: 16px!important;
    line-height: 2.1!important;
    color: #656565!important;
    background-color: #ffff!important;
    background-clip: padding-box;
    border: 1px solid #E2E2E2!important;
    border-radius: 6px!important;
}
.label {
    font-size: 18px!important;
    font-weight: 400!important;
    color: #333333!important;
}
.head_color{
    background: rgba(228, 235, 255, 0.5)!important;
    border: 0.7px solid #E2E2E2!important;
    border-radius: 5px!important;
}
.first-col h6 small{
    text-align: right!important;
}
.first-col-sibling {
    display: flex;
    flex-direction: column;
  
}
.first-col-sibling small{
    padding-top: 10px;
    font-size: 20px;
    color: #656565;
    position: relative;
}
.first-col-sibling small::before {
    content: ":";
    position: absolute;
    left: -40px;
    font-size: 25px;
    top: 3px;
}


.details h6{
    color: #5c5b5b;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
}
.details small{
    color: rgb(39, 50, 53);
    font-size: 20px;
}
.card-body {
    padding: 2rem 1rem!important;
}
h1 {
    text-align: center;
    font-family: Tahoma, Arial, sans-serif;
    color: #06D85F;
    margin: 80px 0;
  }
  
  .box {
    width: 40%;
    margin: 0 auto;
    background: rgba(255,255,255,0.2);
    padding: 35px;
    border: 2px solid #fff;
    border-radius: 20px/50px;
    background-clip: padding-box;
    text-align: center;
  }
  
  /* .button {
    font-size: 1em;
    padding: 10px;
    color: #fff;
    border: 2px solid #06D85F;
    border-radius: 20px/50px;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s ease-out;
  } */
  /* .button:hover {
    background: #06D85F;
  } */
  
  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    visibility: hidden;
    opacity: 0;
  }
  .overlay:target {
    visibility: visible;
    opacity: 1;
  }
  
  .popup {
    margin: 59px auto;
    padding: 30px;
    background: #fff;
    top: 100px;
    border-radius: 5px;
    width: 40%;
    position: relative;
    transition: all 5s ease-in-out;
  }
  
  .popup h2 {
    margin-top: 0;
    color: #333;
    font-family: Tahoma, Arial, sans-serif;
  }
  .popup .close {
    position: absolute;
    top: 20px;
    right: 30px;
    transition: all 200ms;
    font-size: 45px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
  }
  .popup .close:hover {
    color: #06D85F;
  }
  .popup .content {
    max-height: 30%;
  }
  .card{
    border-radius: 9px!important;
  }
  .popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
  }
   
  .box {
    position: relative;
    width: 40%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
  }
  .close-icon {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(33% - 32px);
    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
}
.view-main{
    display: flex;
    justify-content: space-between;
  }
  .first-col h6 {
    padding-top: 9px;
}
.first-col-sibling small {
    color: #656565;
    font-size: 16px;
    padding-top: 10px;
    position: relative;
    padding-bottom: 10px;
}
.card-body {
    padding: 2rem 1rem!important;
}
.first-col h6, .second-col h6 {
    font-size: 15px;
    font-weight: 400;
    padding-bottom: 10px;
}
.first-col-sibling {
    display: flex;
    flex-direction: column;
}
.second-col h6 {
    margin: 2px;
    padding-top: 14px;
}
.details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
}
.pdf{
    position: relative;
}
.pdt_child{
    margin-top: -42px;
    display: flex;
    justify-content: end;
    margin-right: 5px;
    margin-bottom: 25px;
}
.pro-name span{
    font-weight: 600;
    display: contents;
 }
 .Customer_table{
    position: relative;
 }
 .Customer_table_child{
    position: absolute;
    right: 0px;
    bottom: 22px;
 }
 .form-control.muli_select.css-b62m3t-container {
    padding: 0px !important;
}
.table_select {
    background-image: none;
    height: 40px;
    width: 80px;
    background-color: #fff;
    border: 1px solid #d7d6d6;
    text-align: start;
    padding: 5px;
    margin: 0px 10px;
    margin-bottom: 10px;
}

.pro-name img {
    position: relative;
    left: -13px;
}

.container-fluid.px-4 {
    padding-bottom: 1.5rem !important;
}

.MuiInputBase-root.MuiInputBase-colorPrimary.css-16c50h-MuiInputBase-root-MuiTablePagination-select {
    border: 1px solid;
    padding: 0px 10px;
}
p.MuiTablePagination-displayedRows.css-levciy-MuiTablePagination-displayedRows {
    position: relative;
    top: 3px;
    color: currentcolor !important;
}
.css-pdct74-MuiTablePagination-selectLabel {
  margin-bottom: 0px !important;
}
.MuiTablePagination-actions {
    position: relative;
    top: 4px;
}
.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input {
    background: none;
}
.MuiTablePagination-selectLabel{
    color: #000000 !important;
}

.Status_active span{
    background-color: #008000; 
  border: none;
  color: white;
  padding: 4px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  border-radius: 10px 10px 10px 10px;
}
.Status_inactive span{
    background-color: #FF0000;
    border: none;
  color: white;
  padding:4px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  border-radius: 10px 10px 10px 10px;
}

.comments_row{
    display: flex;
}
.comments_row li {
    display: flex;
    padding: 0px 10px;
}

.comments_back{
    padding: 30px;
    width: 100%;
    font-size: 14px;
    overflow: hidden;
    transition: box-shadow .1s linear;
    background-color: #fff;
    border: 1px solid #dbdbdb;
    position: relative;
    cursor: pointer;
    display: block;
    box-shadow: 0 0 0 0 rgba(0,0,0,.15);
    margin-bottom: 8px;
    border-radius: 4px;
}
.comments_row li{
    font-size: 18px;
    font-weight: 500;
}


.tracking{
    display: flex;
}


.tracking_row {
    display: flex;
    justify-content: center !important;
}
.comment_box {
  display: flex;
  justify-content: end !important;
}

textarea.form-control.Chat_box {
    width: 100% !important;
    resize: none !important; 
}
.chat_boxs {
    padding: 16px;
    width: 100%;
   font-size: 14px;
    overflow: auto;
    transition: box-shadow .1s linear;
    background-color: #fff;
    border: 1px solid #dbdbdb;
    position: relative;
    cursor: pointer;
    display: block;
    box-shadow: 0 0 0 0 rgba(0,0,0,.15);
    margin-bottom: 8px;
    border-radius: 4px;
}

.chat_boxs ul li{
    font-size: 20px;
    font-weight: 50px;
    text-decoration: none;
    list-style-type: none ;
    margin-bottom: 5px;

}
.message_left li {
    text-align: end;
}

.comments-list .message_rigth{
    /* display: flex; */
    width: 100%;
    position: relative;
    justify-content: start;
}
.comments-list .message_left{
    /* display: flex; */
    width: 100%;
    position: relative;
    justify-content: end;
    
}
.admin_message{
    font-size: 22px;
    color: #f70f0f;
    font-weight: 600;
}

.message_rigth li span{
    font-size: 22px;
}

.chat_boxs::-webkit-scrollbar {
    display: none;
  }

.search_row{
    display: flex;
    justify-content: end;
}

.Payments_sta h5{
    margin-bottom: 20px;
}

.paid_bnt p{
    padding: 15px;
    background-color: #008000;
    color: #fff;
    text-align: center;
    width: 185px !important;
}
.paid_suss p{
    padding: 15px;
    background-color: #e41709;
    color: #fff;
    text-align: center;
    width: 185px !important;
}

.d-grid.gap-2.d-md-block.mb-3.mt-1.Search_bnt {
    padding: 0px 10px;
}

.message_icon {
    position: relative;
}
.message_icon_child img {
    position: absolute;
    top: 0px;
    right: 50px;
    transform: translate(50%, -70%);
}
.message_icon_child img {
    width: 30px;
}
.Billing_col {
    display: flex;
    justify-content: center !important;
}
.dow_icon{
    width: 20px;
    margin: 0px 10px;
}

button.btn.btn-secondary.me-md-2.dow_bnt {
    padding: 8px 15px !important;
}


/* ..................... */

.Type_row{
    display: block;
    justify-content: end;
}

/* .................... */
.Billing_list ul li {
    font-size: 18px;
    font-weight: 400;
    list-style: none;
    padding: 5px;
    text-align: start;
}
.Billing_row h5{
text-align: center;
}
.Shipment_row {
    padding: 20px;
}
.Shipping_end{
    display: flex;
    justify-content: end;
}
.Shipping_border{
    border-bottom: 1px solid #969393;
    padding: 15px;
}

.gst_border{
    padding: 15px;
}

.Amount_row{
    padding: 15px;
}
.Amount_end{
    background-color:#d3d0ce;
    padding: 5px 30px;
    align-items: center;
    display: flex;
    margin-bottom: 15px;
}
.address_box{
    padding: 30px;
    position: relative;
    margin: 0;
    background:#fff !important;
    box-shadow: 0 10px 29px 0 rgba(68, 88, 144, 0.1);
    transition: all 0.3s ease-in-out;
    border-radius: 4px;
    text-align: center;
    border-bottom: 3px solid #fff;
    height: 360px;
    overflow: auto;
}

.address_box::-webkit-scrollbar {
    display: none;
  }

.address_list{
    display: flex;
    padding: 0px !important;
}
.Billing_list {
    padding-top: 20px;
    padding-bottom: 20px;
}

.all_input h5{
    margin-bottom: 20px;
}

.all_input p{
    padding: 15px;
    position: relative;
    overflow: hidden;
    margin: 0;
    background: #cdcbcb !important;
    box-shadow: 0 10px 29px 0 rgba(68, 88, 144, 0.1);
    transition: all 0.3s ease-in-out;
    border-radius: 4px;
    text-align: center;
    width: 235px !important;
    margin-bottom: 20px;
}

.col-md-4.form-group.Book_col {
display: flex;
justify-content: center !important;
}

.col-md-4.Book_col {
    display: flex;
    justify-content: center !important;
}

.Shipment_row.border {
       margin-bottom: 15px;
}

.dialog_bnt{
    display: flex;
    justify-content: end;
}
.dialog_bnt Button{
    height: 50px !important;
}
.Chat_bnt{
    position: relative;
}
.Chat_bnt_icon img{
    width: 50px;
}
.Chat_bnt_icon {
    position: fixed;
    right: 35px;
    bottom: 20px;
}

.button.btn.btn-secondary.doc_bnt {
    height: 40px !important;
    margin-top: 10px !important;
}
.seach_back{
    display: flex;
    justify-content: center;
    margin-top: 50px;
}
.seach_back img {
    width: 50%;
}

.dialog_bnt Button {
    height: 40px !important;
    margin-top: 5px;
}
.drivername_length{
    position: relative;
}
.drivername_length_child {
    position: absolute;
    top: -19px;
    left: 85px;
}
.drivername_length_child h5 {
    background-color:#c5c5c5;
    padding: 4px 10px;
    border-radius: 15px 15px 15px 15px;
    font-size: 15px;
    font-weight: 600;
}
.Order_bnt button{
    width: 60% !important;
    padding: 10px !important;
}

 .table-responsive .Customer_table p{
    color: #008000 !important;
  }

  .first-col {
    margin-top: 3px !important
}
.first-col-sibling {
   margin-left: 20px;
   
}
p.Kyc_p {
    color: #008000;
}
p.Kyc_Pending {
    color: #0000FF;
}
p.Kyc_reject{
    color: #FF0000;
}
p.Kyc_upload {  
    color: #ffc107;
        }
        
/* ....................................................... */
.Kyc_table p.Kyc_p {
    background-color: #008000; 
    border: none;
    color: white;
    padding: 8px !important;
    width: 100% !important;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
     border-radius: 10px 10px 10px 10px;
     font-size: 12px;
}

.Kyc_table p.Kyc_Pending {
    background-color: #0000FF;
    border: none;
    color: white;
    padding: 8px !important;
    width: 100% !important;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    border-radius: 10px 10px 10px 10px;
    font-size: 12px;
}
.Kyc_table p.Kyc_reject {
    background-color: #FF0000;
    border: none;
    color: white;
    padding: 8px !important;
    width: 100% !important;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    font-size: 12px;
    border-radius: 10px 10px 10px 10px;
}

.Kyc_table .Status_active span{
    background-color: #008000 !important; 
    border: none;
    color: white;
    padding: 8px !important;
    width: 100% !important;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    font-size: 12px;
    border-radius: 10px 10px 10px 10px;
}
.Kyc_table .Status_inactive span{
    background-color: #FF0000 !important;
    border: none;
    color: white;
    padding: 8px !important;
    width: 100% !important;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    font-size: 12px;
    border-radius: 10px 10px 10px 10px;
}


.Kyc_table p.Kyc_upload {  
    background-color: #ffc107;
    border: none;
    color: white;
    padding: 8px !important;
    width: 100% !important;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    border-radius: 10px 10px 10px 10px;
    font-size: 12px;
}



.star_icon{
    color: #FF0000;
    font-size: 22px;
}
.otp_css{
    color: #0b308d;
}
.password-input {
  position: relative;
}

.password-toggle-btn {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #333;
  cursor: pointer;
}
.new_icon {
    width: 30px;
    margin: 0px 10px;
    transform: rotate(269deg);
}
.old_name {
    color: #d61f1f;
    margin: 10px;
}
.new_name{
    color: #0000FF;
}
.payment_icon img{
width: 30px;
cursor: pointer;
}
.report_img img{
    width: 30%;
}
.report_img{
    display: flex;
    justify-content: center;
}

.customer_message{
    color: #008000;
}
.driver_message{
    color: #0000FF;
}
.vendor_message{
    color: #050c54;
}

.old_drivers{
    display: flex;
}
.old_driver-title {
    display: flex;
    justify-content: space-between;
    width: 78%;
    padding: 10px;
}
.Success_message{
    display: flex;
    justify-content: center;
}

  
.Shipping_title h6{
    font-size: 20px;
    font-weight: 400;
}

.payment_status{
    background-color: #0000FF;
    border: none;
    color: white;
    padding: 8px !important;
    width: 100% !important;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    border-radius: 10px 10px 10px 10px;
}
.payment_unpaid{
    background-color: #FF0000;
    border: none;
    color: white;
    padding: 8px !important;
    width: 100% !important;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    border-radius: 10px 10px 10px 10px;
}
button.docment_box {
    padding: 4px;
    background-color: #fff;
    border: 3px solid #0c1cd7;
    color: #0f32e1;
    border-radius: 10px;
}
.approve{
    position: relative;
}
.approve_bnt button{
    position: absolute;
    left: -93px;
}

.approve_bnt button:hover {
    background-color: #ffc107 !important;
    border: none !important;
}

.approve_bnt.approved {
    position: absolute;
    left: -10px;
}

.approve_bnt.approved button:hover {
        background-color: #198754 !important;
        border: none !important;
    }
 .shippingchargelist h6 {
      margin-left: 0px;
      padding: 5px;
 }

 /* ...................................................... */
 a.btn.btn-primary.admin.float-end {
    padding: 10px 20px !important;
}

a.btn.btn-primary.admin.float-end {
    background-image: linear-gradient(to left, rgba(10, 52, 110, 0.4), #28396D)!important;
    color: #FFFFFF !important;
    font-size: 14px !important;
}

a.btn.btn-primary.admin.float-end :hover {
    color:unset !important;
    background-color:unset !important;
    border-color: unset !important;
}

.btn.btn-success.admin {
    padding: 10px 20px !important;
    font-style: normal;
    font-weight: 500;
    font-size: 16px !important;
    line-height: 28px !important;
    color: #FFFFFF;
}

.titile_span {
    position: relative;
    top: 12px;
}
button.btn.btn-success.add_bnt {
    position: relative;
    top: 10px;
    padding: 8px 25px !important;
}

button.btn.btn-success.sec_bnt {
    top: -5px;
    position: relative;
    padding: 10px 20px !important;
    left: -10px;
}
.d-grid.gap-2.d-md-flex.justify-content-md-end.Edit_box {
    position: relative;
    top: -15px;
}
.Vehicle_tooltip {
    cursor: pointer;
}

.UploadFile{
    position: relative;
}
.UploadFile_img {
    position: absolute;
    top: 26px;
    right: 17px;
}
.UploadFile_img img {
    width: 25px;
}
button.btn.btn-primary.add_bnt {
    width: 100px;
    height: 50px;
    position: relative;
    top: 12px;
}
button.btn.btn-danger.remove_bnt {
    /* padding: 8px 10px !important; */
    top: 3px;
    position: relative;
    width: 100px;
    height: 50px;
}
small.message_text {
    max-width: 385px;
}

/* ............................................... */
.home .main-butt {
    margin-top: 30px !important;
}
.home .butt-home {
    min-height: 15vh !important;
}
.masterlayout .heading h5 {
    padding-left: 40px !important;
    line-height: 57px !important;
    font-size: 18px !important;
}
.table th {
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 28px !important;
}
td {
    font-size: 14px !important;
    font-weight: 500 !important;
}

.title h4 {
    font-size: 22px;
    line-height: 36px;
    font-weight: 500;
}

.titile h4{
    font-size: 22px;
    line-height: 36px;
    font-weight: 500;
}

label {
    margin-bottom: 10px !important;
}
/* ...............................mobile........................................ */
/* Ipad mini: */
@media (min-width: 700px) and (max-width: 960px){
    .icons {
        width: 50px;
        height: 50px;
        right: 13px;
        top: 24px;
    
    }
    .icons img{
        width: 30px;
    }
    .title h5 {
        font-size: 15px;
        font-weight: 500;
        line-height: 28px;
    }
    .value p{
        font-size: 14px;
    margin-bottom: 0px !important;
    }
    .value svg{
        width: 15px !important;
    }
    .heads h6 {
        font-size: 20px;
    }
    .approval .title h4 {
        font-size: 20px !important;
        line-height: 32px !important;
    }
}

/* ...........................mobile......................... */
/* mobile: */
@media (max-width: 660px){
    .home .butt-home {
        min-height: 0vh !important;
    }
    .login .card-body {
        padding: 20px!important;
    }
    img.profile-admin {
        width: 50%;
        margin-bottom: 30px;
        border-radius: 50%;
    }
    .profile-img {
    display: flex;
    justify-content: center;
    }
    .masterlayout .btn-link {
        font-weight: 400;
        color: #d5dae0;
        text-decoration: underline;
        position: absolute;
        right: -23px;
        padding: 0px;
        margin: 0px;
    }
    button.dropdown-item {
        padding: 0px;
    }

    ul.navbar-nav.ms-auto.ms-md-0.me-3.me-lg-4 {
        display: flex;
        align-items: center;
        color: #fff !important;
        position: absolute;
        top: 26px;
        right: 14px;
    }
    .heading {
        display: none;
    }
    .sb-sidenav-menu {
        margin-top: 15px;
    }
    a.dropdown-item {
        padding: 0px;
    }
    .row.px-4.py-4.admin-dash {
        width: 100%;
        margin: 0px;
    }
    a.btn.btn-primary.admin.float-end {
        padding: 10px 10px !important;
    }
    .titile_span {
        top: 5px;
    }
    button#test-table-xls-button {
        display: none;
    }
    .titile h4 {
        font-size: 20px;
    }
    .row.px-5 {
        padding: 0px !important;
    }
    .card-body.px-3.mt-3.mb-3 {
        margin: 3px !important;
        padding: 15px !important;
    }
    .first-col h6, .second-col h6 {
        font-size: 15px;
        font-weight: 400;
        padding-bottom: 10px;
        width: 120px;
    }
    .first-col-sibling small::before {
        content: ":";
        position: absolute;
        left: -40px;
        font-size: 25px;
        top: 7px;
    }
    .first-col-sibling {
        margin-left: 50px;
    }
    .col-md-12.view-main {
        overflow: auto;
    }
    .first-col-sibling small {
        color: #656565;
        font-size: 16px;
        padding-top: 15px;
        position: relative;
        padding-bottom: 5px;
        width: 260px;
    }
    div#pills-tabContent {
        overflow: auto;
    }
    #layoutSidenav {
        overflow: hidden;
    }
    div#lottie {
        left: -4rem;
        position: absolute;
    }
    .second-bellnull {
        top: 65px;
        left: 54px;
    }
    .cards::after {
        right: 99px;
        top: -15px;
    }
}