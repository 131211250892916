.customer-view .mobile {
  display: flex;
  justify-content: space-between;
}
.customer-view .cutomer-details h6 {
  padding-bottom: 5px;
}
.customer-view .other-details h6 {
  padding-bottom: 5px;
}
.customer-view .details h6 {
  color: #5c5b5b;
  font-size: 20px;
}
.customer-view .details small {
  color: rgb(39, 50, 53);
  font-size: 20px;
}/*# sourceMappingURL=customer.css.map */