.Driver{

    .button_status {
            border-radius: 5px;
            width: 139px;
            padding: 9px;
        
        &.Active {
            background-color: #DCDAF3;
            opacity: 0.7;
            color: #6546D4;
        }
        &.Inactive {
            background-color: #FFC9C9;
            color: #FF2929;
        }
        &.Pending{
            background-color: #FF9F43;
            opacity: 0.5;
            color: #4d2704;
        }
        &.Not{
            background-color: #dfab7c;
            opacity: 0.5;
            color: #3a230e;
        }
        &.Delivered{
            background-color: #ad849d;
            opacity: 0.9;
            color: #641D49;
        }
        &.Approved{
            background-color: #9cafc9 ;
            opacity: 0.9;
            color: #1D73E7 ;
        }
        &.Rejected{
            background-color: #cc8e91  ;
            opacity: 0.9;
            color: #EF4D56  ;
        }
        &.Reject{
            background-color: #cc8e91  ;
            opacity: 0.9;
            color: #EF4D56  ;
        }
        &.Truck{
            background-color: #b694cf  ;
            opacity: 0.9;
            color: #8D47C4  ; 
        }
        &.Completed{
            background-color: #84c0ab  ;
            opacity: 0.9;
            color: #22B783  ; 
        }
        &.Available{
            background-color: #84c0ab  ;
            opacity: 0.9;
            color: #f8faf9  ; 
        }
        &.Paid{
            background-color: #399b79  ;
            opacity: 0.9;
            color: #9bcfbc  ; 
        }
        &.Unpaid{
            background-color: #a04151  ;
            opacity: 0.9;
            color: #c8dad3  ; 
        }
      
    }
}
