.Editvechile{
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
        color: #fff;
        background-color:  #909297;;
    }
    .nav-link:focus, .nav-link {
        color: #131414;
    }
    .nav-link:focus, .nav-link:hover{
        color: #131414;
    }

}