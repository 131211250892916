.customer-view{
    .mobile{
        display: flex;
        justify-content:space-between;
    }
    .cutomer-details h6{
        padding-bottom: 5px;
    }
    .other-details h6{
        padding-bottom: 5px;
    }
    .details h6{
        color: #5c5b5b;
        font-size: 20px;
    }
    .details small{
        color: rgb(39, 50, 53);
        font-size: 20px;
    }
}