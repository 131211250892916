.Addvechile{
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
        color: #fff;
        background-color:  #8197ce;;
    }
    .nav-link:focus, .nav-link {
        color: #131414;
    }
    .nav-link:focus, .nav-link:hover{
        color: #131414;
    }
    .admin-view{
        display: flex;
    }
    ul#pills-tab {
        display: flex;
        gap: 9px;
    }

    .admin-view button{
        width: 214px;
        font-size: 20px;
        padding: 5px;
    }
    .admin-view .butt-view{
        text-align: center;
    }
}