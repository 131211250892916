.approval{
    tbody h6{
        text-align: center;
    }
    .back-table th{
        font-weight: 500;
        font-size: 16px;
    }
    .title h4{
        color: 
        #28396D;
    }
    table th{
        font-weight: 400;
    }
}