.approval tbody h6 {
  text-align: center;
}
.approval .back-table th {
  font-weight: 500;
  font-size: 16px;
}
.approval .title h4 {
  color: #28396D;
}
.approval table th {
  font-weight: 400;
}/*# sourceMappingURL=approval.css.map */