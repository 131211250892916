.Driver .button_status {
  border-radius: 5px;
  width: 139px;
  padding: 9px;
}
.Driver .button_status.Active {
  background-color: #DCDAF3;
  opacity: 0.7;
  color: #6546D4;
}
.Driver .button_status.Inactive {
  background-color: #FFC9C9;
  color: #FF2929;
}
.Driver .button_status.Pending {
  background-color: #FF9F43;
  opacity: 0.5;
  color: #4d2704;
}
.Driver .button_status.Not {
  background-color: #dfab7c;
  opacity: 0.5;
  color: #3a230e;
}
.Driver .button_status.Delivered {
  background-color: #ad849d;
  opacity: 0.9;
  color: #641D49;
}
.Driver .button_status.Approved {
  background-color: #9cafc9;
  opacity: 0.9;
  color: #1D73E7;
}
.Driver .button_status.Rejected {
  background-color: #cc8e91;
  opacity: 0.9;
  color: #EF4D56;
}
.Driver .button_status.Reject {
  background-color: #cc8e91;
  opacity: 0.9;
  color: #EF4D56;
}
.Driver .button_status.Truck {
  background-color: #b694cf;
  opacity: 0.9;
  color: #8D47C4;
}
.Driver .button_status.Completed {
  background-color: #84c0ab;
  opacity: 0.9;
  color: #22B783;
}
.Driver .button_status.Available {
  background-color: #84c0ab;
  opacity: 0.9;
  color: #f8faf9;
}
.Driver .button_status.Paid {
  background-color: #399b79;
  opacity: 0.9;
  color: #9bcfbc;
}
.Driver .button_status.Unpaid {
  background-color: #a04151;
  opacity: 0.9;
  color: #c8dad3;
}/*# sourceMappingURL=driver.css.map */