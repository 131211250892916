.home {
    background-image: url(../../../assets/admin/assets/img/home.png);
    background-position: center;
    background-size: cover;
    width: 100%;
    min-height: 100vh;
    font-family: 'Poppins', sans-serif;

    .butt-home {
        display: flex;
        justify-content: center;
        min-height: 20vh;
    }

    .form-group.mb-3.col-md-6 {
        text-align: center;
    }

    .form-group.mb-3.col-md-12 {
        text-align: center;
    }

    .main-butt {
        margin-top: 50px;
    }

    .nav-pills .nav-link.active,
    .nav-pills .show>.nav-link {
        color: #fff;
        background-color: #0dfdf285;
    }

    .nav-pills .nav-link:hover {
        color: #ffff
    }
      .card>.card-body {
        border-radius: 20px!important;
    }
}